import React from "react"

export default function MenuItem(props) {
  return (
    <div className="menu-item-container">
      <h4 className={`is-size-4 menu-section-title ${props.price ? "" : "no-price"}`}>
        <span className="menu-item">
          {props.name}
          {props.footnoteNumber &&
            <sup className="footnote-number">*{props.footnoteNumber}</sup>
          }
        </span>
        {props.price &&
          <span className="price">${props.price.toFixed(2)}</span>
        }
      </h4>
      {props.description &&
        <p className="menu-item-description is-size-7">{props.description}</p>
      }
    </div>
  )
}