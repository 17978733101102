import React from "react"

export default function HouseWines(props) {
  return (
    <div className="alcohol-table is-fullwidth">
      <div className="alcohol-table-titles columns is-mobile is-variable is-2 has-text-centered" style={{whiteSpace: "nowrap"}}>
        <div className="column">
          <h4>Wine</h4>
        </div>
        <div className="column is-2">
          <h4>6 oz</h4>
        </div>
        <div className="column">
          <h4>1/2 Litre</h4>
        </div>
        <div className="column is-2">
          <h4>Litre</h4>
        </div>
      </div>
      {props.drinks.map((drinkItem, itemIndex) => (
        <div className="alcohol-table-content has-text-centered columns is-mobile is-size-7 is-variable is-2" key={itemIndex}>
          <div className="column">
            <h4 className="has-text-left">{drinkItem.name}</h4>
          </div>
          <div className="column is-2">
            <h4>${drinkItem.price6oz.toFixed(2)}</h4>
          </div>
          <div className="column">
            <h4>${drinkItem.priceHalfLitre.toFixed(2)}</h4>
          </div>
          <div className="column is-2">
            <h4>${drinkItem.priceLitre.toFixed(2)}</h4>
          </div>
        </div>
      ))}    
    </div>
  )
}