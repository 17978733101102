import React from "react"

import MenuItem from "../components/menu-item"

export default function FoodMenuSection(props) {
  return (
    <section className={"section food-menu"} id={props.title}>
      <div className="container">
        <h2 className="has-text-centered is-size-3">{props.title}</h2>
        {props.subtitle &&
          <h3 className="has-text-centered is-size-5">{props.subtitle}</h3>
        }
        {props.description &&
          <p className="menu-description" style={{marginTop: "10px"}}>{props.description}</p>
        }
        <div className="green-underline"></div>

        <div className="columns is-gapless-mobile">
          {props.columnList.map((columnItem, columnIndex) => (
            <div className="column" key={columnIndex}>
              {columnItem.menuItemList.map((menuItem, itemIndex) => (
                <MenuItem key={itemIndex} name={menuItem.name} price={menuItem.price} description={menuItem.description} footnoteNumber={menuItem.footnoteNumber} />
              ))}

              {(props.footnoteList && columnIndex == props.columnList.length - 1) &&  
                <div>     
                  {props.footnoteList.map((footnoteItem, footnoteIndex) => (
                    <div className="footnote is-size-7" key={footnoteIndex}>
                      <p><span><sup>*{footnoteItem.footnoteNumber}</sup>{footnoteItem.footnoteTitle} </span>– {footnoteItem.footnoteDescription}</p>
                    </div>
                  ))}
                </div>
              }
            </div>
          ))}
        </div>     
      </div>
    </section>
  )
}