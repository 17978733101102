import React from "react"

export default function SkipLinks(props) {
  return (
    <section className={"skip-links"}>
      <div className="container columns">
        <div className="column">
          <ul>
            {props.skipLinkList.map((item, index) => (
              <li key={index}>
                <a href={"#" + item.title}>
                 {item.skipLink
                   ? <>{item.skipLink}</>
                   : <>
                     {item.subtitle
                       ? <>{item.subtitle}</>
                       : <>{item.title}</>
                     } 
                     </>
                 }
                </a>
              </li>
            ))}
            <li>
              <a href={"#" + props.alcoholicBeveragesTitle}>
                {props.alcoholicBeveragesTitle}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}