import React from "react"

export default function AlcoholTable(props) {
  return (
    <div className="alcohol-table is-fullwidth">
      <div className="alcohol-table-titles columns is-mobile is-variable is-2 has-text-centered">
        <div className="column">
          <h4>Draught</h4>
        </div>
        <div className="column">
          <h4>12oz</h4>
        </div>
        <div className="column">
          <h4>20oz</h4>
        </div>
        <div className="column">
          <h4>Pitcher</h4>
        </div>
      </div>
      {props.drinks.map((drinkItem, itemIndex) => (
        <div className="alcohol-table-content has-text-centered columns is-mobile is-size-7 is-variable is-2" key={itemIndex}>
          <div className="column">
            <h4 className="has-text-left">{drinkItem.name}</h4>
          </div>
          <div className="column">
            <h4>${drinkItem.price12oz.toFixed(2)}</h4>
          </div>
          <div className="column">
            <h4>${drinkItem.price20oz.toFixed(2)}</h4>
          </div>
          <div className="column">
            <h4>${drinkItem.pricePitcher.toFixed(2)}</h4>
          </div>
        </div>
      ))}
     
    </div>
  )
}