import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import SkipLinks from "../components/skip-links"
import FoodMenuSection from "../components/food-menu"
import DrinksSection from "../components/drinks-section"
import BackgroundDrawings from "../components/background-drawings"

import remark from 'remark'
import remarkHTML from 'remark-html'

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark
  const pvBanner = "../menu/pv-menu-banner.jpg";

  const menuSectionList = frontmatter.menuSectionList;

  return (
    <Layout title="Menu" description="Pancho Villa's food and drink menu." >  
      <Banner title="Menu" imageUrl={pvBanner} />
      <SkipLinks skipLinkList={frontmatter.menuSectionList} alcoholicBeveragesTitle={frontmatter.drinks.alcoholicBeverages.subtitle} />
      <BackgroundDrawings>        
        {menuSectionList.map((item, index) => (
          <FoodMenuSection key={index} title={item.title} subtitle={item.subtitle} description={item.description} columnList={item.columnList} footnoteList={item.footnoteList} />
        ))}
        <DrinksSection alcoholicBeverages={frontmatter.drinks.alcoholicBeverages} wineMenu={frontmatter.drinks.wineMenu} houseSpecialties={frontmatter.drinks.houseSpecialties} panchosFinales={frontmatter.drinks.panchosFinales} seasonalDrinks={frontmatter.drinks.seasonalDrinks}  />
      </BackgroundDrawings>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        menuSectionList {
          title
          subtitle
          description
          skipLink
          columnList {
            menuItemList {
              name
              price
              description
              footnoteNumber
            }
          }
          footnoteList {
            footnoteNumber
            footnoteTitle
            footnoteDescription
          }
        }
        drinks {
          alcoholicBeverages {
            title
            subtitle
            beer {
              menuItemList {
                name
                price
                description
              }
              draughtList {
                name
                price12oz
                price20oz
                pricePitcher
              }
            }
            sectionList {
              title
              menuItemList {
                name
                price
              }
            }
          }
          wineMenu {
            title
            red {
              wineList {
                name
                location
                description
                price6oz
                priceBottle
              }
              houseWines {
                name
                price6oz
                priceHalfLitre
                priceLitre
              }
            }
            white {
              wineList {
                name
                location
                description
                price6oz
                priceBottle
              }
              houseWines {
                name
                price6oz
                priceHalfLitre
                priceLitre
              }
            }
          }
          houseSpecialties {
            title
            columnList {
              sectionList {
                title
                description
                menuItemList {
                  name
                  price
                }
              }
            }
          }
          panchosFinales {
            title
            price
            columnList {
              drinkList {
                name
                description
              }
            }
          }
          seasonalDrinks {
            title
            columnList {
              drinkList {
                name
                description
                price
              }
            }
          }
        }
      }
    }
  }
`