import React from "react"
import AlcoholTable from "../components/alcohol-table"
import MenuItem from "../components/menu-item"
import WineTable from "../components/wine-table"
import HouseWines from "../components/house-wines"

export default function DrinksSection(props) {
  return (
    <div>
      <section className={"section food-menu"} id={props.alcoholicBeverages.subtitle}>
        <div className="container">
          <h2 className="has-text-centered is-size-3">{props.alcoholicBeverages.title}</h2>
          {props.alcoholicBeverages.subtitle &&
            <h3 className="has-text-centered is-size-5">{props.alcoholicBeverages.subtitle}</h3>
          }
          <div className="green-underline"></div>
          <div className="columns">
            <div className="column">
              {props.alcoholicBeverages.beer.menuItemList.map((menuItem, itemIndex) => (
                <MenuItem key={itemIndex} name={menuItem.name} price={menuItem.price} description={menuItem.description} />
              ))}
              <AlcoholTable drinks={props.alcoholicBeverages.beer.draughtList} />
            </div>
            <div className="column">
              {props.alcoholicBeverages.sectionList.map((sectionItem, sectionIndex) => (
                <div key={sectionIndex} className="mini-drink-section">
                  <h2 className="has-text-centered is-size-4">{sectionItem.title}</h2>
                  {sectionItem.menuItemList.map((menuItem, itemIndex) => (
                    <MenuItem key={itemIndex} name={menuItem.name} price={menuItem.price} />
                  ))}
                </div>
              ))}
            </div>
          </div>     
        </div>
      </section>

      <section className={"section food-menu"}>
        <div className="container">
          <h2 className="has-text-centered is-size-3">{props.wineMenu.title}</h2>
          <div className="green-underline"></div>
          <div className="columns">
            <div className="column">
              <h2 className="has-text-centered is-size-4">White Wines</h2>
              <WineTable drinks={props.wineMenu.white.wineList} />
              <HouseWines drinks={props.wineMenu.white.houseWines} />
            </div>
            <div className="column">
              <h2 className="has-text-centered is-size-4">Red Wines</h2>
              <WineTable drinks={props.wineMenu.red.wineList} />
              <HouseWines drinks={props.wineMenu.red.houseWines} />
            </div>
          </div>  
        </div>
      </section>

      <section className={"section food-menu"}>
        <div className="container">
          <h2 className="has-text-centered is-size-3">{props.houseSpecialties.title}</h2>
          <div className="green-underline"></div>
          <div className="columns">
            {props.houseSpecialties.columnList.map((columnItem, columnIndex) => (
              <div className="column" key={columnIndex}>
                {columnItem.sectionList.map((sectionItem, sectionIndex) => (
                  <div key={sectionIndex} className="mini-drink-section">
                    <h2 className="has-text-centered is-size-4">{sectionItem.title}</h2>
                    <p className="menu-description" style={{marginBottom: "25px"}}>{sectionItem.description}</p>

                    {sectionItem.menuItemList &&
                      <div>
                        {sectionItem.menuItemList.map((menuItem, itemIndex) => (
                          <MenuItem key={itemIndex} name={menuItem.name} price={menuItem.price} />
                        ))} 
                      </div>  
                    }
                  </div>
                ))}   
              </div>
            ))}
          </div>  
        </div>
      </section>

      <section className={"section food-menu panchos-finales"}>
        <div className="container">
          <h2 className="has-text-centered is-size-3">{props.panchosFinales.title}</h2>
          <p className="has-text-centered is-size-5">${props.panchosFinales.price.toFixed(2)}</p>
          <div className="green-underline"></div>
          <div className="columns is-gapless-mobile">
            {props.panchosFinales.columnList.map((columnItem, columnIndex) => (
              <div className="column" key={columnIndex}>
                {columnItem.drinkList.map((drinkItem, drinkIndex) => (  
                  <MenuItem key={drinkIndex} name={drinkItem.name} description={drinkItem.description} />
                ))} 
              </div>
            ))} 
          </div>  
        </div>
      </section>

      <section className={"section food-menu"}>
        <div className="container">
          <h2 className="has-text-centered is-size-3">{props.seasonalDrinks.title}</h2>
          <div className="green-underline"></div>
          <div className="columns is-gapless-mobile">
            {props.seasonalDrinks.columnList.map((columnItem, columnIndex) => (
              <div className="column" key={columnIndex}>
                {columnItem.drinkList.map((drinkItem, drinkIndex) => (  
                  <MenuItem key={drinkIndex} name={drinkItem.name} description={drinkItem.description} price={drinkItem.price} />
                ))} 
              </div>
            ))} 
          </div>  
        </div>
      </section>
    </div>
  )
}